import styled from "styled-components";
import * as SH from "@shared";
import * as theme from "@theme";
export const WrapperBox = styled(SH.Wrapper.Box)`
  padding: 40px 55px;
  height: 645px;
  @media ${theme.breakpoints.mobile} {
    padding: 20px;
  }
  @media ${theme.breakpoints.desktop} {
    height: auto;
  }
  &:nth-of-type(even) {
    height: 645px;

    @media ${theme.breakpoints.desktop} {
      .col-text {
        padding: 40px 55px;
      }
      height: auto;
    }
  }
`;

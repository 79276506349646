import { useStaticQuery, graphql } from "gatsby";
import { THero } from "@types";

const useFetchHomePage = () => {
  const data: TData = useStaticQuery(graphql`
    {
      allStrapiHomePage(sort: { fields: id }) {
        edges {
          node {
            coreValues {
              title
              id
              description
              image {
                alternativeText
                localFile {
                  publicUrl
                }
              }
            }
            hero {
              title
              bgMobile {
                localFile {
                  publicUrl
                }
              }
              description
              bgDesktop {
                localFile {
                  publicUrl
                }
              }
            }
            titleBlue
            imageHero {
              localFile {
                publicUrl
              }
            }
          }
        }
      }
    }
  `);

  return { data };
};

export default useFetchHomePage;

type TData = {
  allStrapiHomePage: {
    edges: {
      node: {
        coreValues: {
          title: string;
          id: number;
          description: string;
          image: {
            alternativeText: string;
            localFile: {
              publicUrl: string;
            };
          };
        }[];
        hero: THero;
        titleBlue: string;
        imageHero: {
          localFile: {
            publicUrl: string;
          };
        };
      };
    }[];
  };
};

import React from "react";
import ReactHtmlParser from "react-html-parser";
import * as SH from "@shared";
import * as S from "./CoreValues.styles";
import { Row, Container, Col } from "react-bootstrap";
import useFetchCoreValues from "../HomePage.hooks";

export const CoreValues = () => {
  const { data } = useFetchCoreValues();

  return (
    <SH.Wrapper.Primary
      colorComponent="whitegray"
      paddingTop={{ mobile: 24, desktop: 125 }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col xs="11" lg="10">
            <SH.Text.Header2 className="mb-5">our core values</SH.Text.Header2>

            {!!data.allStrapiHomePage.edges[0].node.coreValues &&
              data.allStrapiHomePage.edges[0].node.coreValues.map(
                ({ title, description, image, id }) => (
                  <S.WrapperBox key={id}>
                    <Row className="h-100 justify-content-between row-box ">
                      <Col
                        lg="6"
                        xl="5"
                        className="d-flex flex-column justify-content-center align-self-lg-center col-text"
                      >
                        <SH.Text.Header3>{title && title}</SH.Text.Header3>
                        <SH.Text.MarkdownSubTitle1 className="pt-4">
                          {description && ReactHtmlParser(description)}
                        </SH.Text.MarkdownSubTitle1>
                      </Col>
                      <Col
                        className="d-flex justify-content-center align-self-lg-center"
                        lg="6"
                      >
                        {image && (
                          <SH.Image
                            heightImg={heightImg}
                            src={image?.localFile.publicUrl}
                            alt={image?.alternativeText}
                          />
                        )}
                      </Col>
                    </Row>
                  </S.WrapperBox>
                )
              )}
          </Col>
        </Row>
      </Container>
    </SH.Wrapper.Primary>
  );
};

const heightImg = {
  desktop: 314.99,
  mobile: 239.69,
};

import React from "react";
import * as SH from "@shared";
import * as S from "./HeroSection.styles";
import { Row, Container, Col } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import useFetchHero from "./HomePage.hooks";

export const HeroSection = () => {
  const { data } = useFetchHero();

  return (
    <SH.Wrapper.HeroSectionHome
      bgMobile={
        data.allStrapiHomePage.edges[0].node.hero.bgMobile.localFile?.publicUrl
      }
      bgDesktop={
        data.allStrapiHomePage.edges[0].node.hero.bgDesktop.localFile?.publicUrl
      }
    >
      <Container>
        <Row>
          <Col
            className="order-lg-2 d-flex justify-content-center mb-3 mb-lg-0"
            lg="6"
          >
            <SH.Image
              src={
                data.allStrapiHomePage.edges[0].node.imageHero.localFile
                  .publicUrl
              }
              heightImg={height}
            ></SH.Image>
          </Col>
          <Col
            className="order-lg-1 text-center text-lg-left d-flex flex-column align-items-center align-items-lg-start justify-content-lg-center"
            lg="6"
          >
            <S.Header1 className="mb-3">
              {data.allStrapiHomePage.edges[0].node.hero.title}
              <SH.Text.Span colorComponent="lightblue">
                <br />
                {data.allStrapiHomePage.edges[0].node.titleBlue}
              </SH.Text.Span>
            </S.Header1>
            <SH.Wrapper.WrapperWysiwygHero className="pb-5 pt-lg-3">
              {data.allStrapiHomePage.edges[0].node.hero.description &&
                ReactHtmlParser(
                  data.allStrapiHomePage.edges[0].node.hero.description
                )}
            </SH.Wrapper.WrapperWysiwygHero>
          </Col>
        </Row>
      </Container>
    </SH.Wrapper.HeroSectionHome>
  );
};

const height = {
  desktop: 421,
  mobile: 253,
};

import React from "react";
import { graphql } from "gatsby";
import Layout from "@components/Layout";
import * as C from "@components/HomePage";
import { TSeoHome } from "@types";

const IndexPage = ({ data }: TSeoHome) => {
  const { seo } = data.strapiHomePage;

  return (
    <Layout seo={seo}>
      <C.HeroSection />
      <C.CoreValues />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    strapiHomePage {
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicUrl
          }
        }
      }
    }
  }
`;
